<template>
  <div>
    <simple-form-element
      :model.sync="model[input.prop]"
      :config="input"
    />

    <simple-form-element
      :model.sync="model[select.prop]"
      :config="select"
    />

    <simple-form-element
      :model.sync="model[searchableSelect.prop]"
      :config="searchableSelect"
    />

    <simple-form-element
      :model.sync="model[radioGroup.prop]"
      :config="radioGroup"
    />

    <simple-form-element
      :model.sync="model[checkboxGroup.prop]"
      :config="checkboxGroup"
    />

    <simple-form-element
      :model.sync="model[switchConfig.prop]"
      :config="switchConfig"
    />

    <simple-form-element
      :model.sync="model[datepickerConfig.prop]"
      :config="datepickerConfig"
    />

    {{ model }}
  </div>
</template>
<script>
export default {
  name: 'DemoFormElement',
  data () {
    return {
      // select dropdown list loading flag
      remoteLoading: false,
      // select dropdown open status
      remoteListOpenStatus: false,
      // dropdown result set
      userList: [],

      model: {
        name: 'zhangmeng',
        grade: '',
        user: {},
        sex: 'dict_1val',
        checkList: ['dict_1val', 'dict_2val']
      },
      input: {
        type: 'el-input',
        prop: 'name',
        component: {
          type: 'text',
          clearable: true,
          // showWordLimit: true,
          placeholder: 'Input Username'
          // showPassword: true
          // disabled: true
        }
      },

      select: {
        type: 'el-select',
        prop: 'grade',
        options: [
          { id: 'test1', label: 'dict_1label', value: 'dict_1val' },
          { id: 'test2', label: 'dict_2label', value: 'dict_2val' },
          { id: 'test3', label: 'dict_3label', value: 'dict_3val' }
        ],
        component: {
          clearable: true,
          valueKey: 'id'
        },
        event: {
          change: val => {
            console.log(val)
          }
        }
      }

    }
  },

  computed: {
    searchableSelect () {
      return {
        type: 'el-select',
        prop: 'user',
        options: this.userList,
        remoteUrl: '/system/user/list',
        component: {
          valueKey: 'id',
          filterable: true,
          remote: true,
          loading: this.remoteLoading,
          remoteMethod: this.handleSearchUser,
          clearable: true
        },
        event: {
          focus: _ => this.handleSearchUser(),
          'visible-change': status => this.handleCloseList(status)
        }
      }
    },

    radioGroup () {
      return {
        type: 'el-radio-group',
        prop: 'sex',
        options: [
          { id: 'test1', label: 'dict_1label', value: 'dict_1val' },
          { id: 'test2', label: 'dict_2label', value: 'dict_2val' },
          { id: 'test3', label: 'dict_3label', value: 'dict_3val' }
        ],
        component: {}
      }
    },

    checkboxGroup () {
      return {
        type: 'el-checkbox-group',
        prop: 'checkList',
        options: [
          { id: 'test1', label: 'dict_1label', value: 'dict_1val' },
          { id: 'test2', label: 'dict_2label', value: 'dict_2val' },
          { id: 'test3', label: 'dict_3label', value: 'dict_3val' }
        ],
        component: {}
      }
    },

    switchConfig () {
      return {
        type: 'el-switch',
        prop: 'type',
        options: [
          { id: 'test1', label: 'dict_1label', value: 'dict_1val' },
          { id: 'test2', label: 'dict_2label', value: 'dict_2val' }
        ],
        component: {
          // activeText: 'dict_1label',
          // inactiveText: 'dict_2label',
          activeValue: 'dict_1val',
          inactiveValue: 'dict_2val'
        }
      }
    },

    datepickerConfig () {
      return {
        type: 'el-date-picker',
        prop: 'datepicker',
        component: {
          editable: false,
          clearable: false,
          valueFormat: 'yyyy-MM-dd'
        }
      }
    }
  },

  methods: {
    handleSearchUser (name) {
      // solve blur and focus problem
      if (this.remoteListOpenStatus && typeof name === 'undefined') return
      const params = Object.assign({}, { page: { pageSize: 20 } }, this.$utils.parseQueryCondition({ name }))

      this.remoteLoading = true
      this.$axios
        .post('/system/user/list', params)
        .then(resp => {
          const { data } = resp
          this.userList = data.list.map(item => {
            return {
              id: item.id,
              value: item,
              label: item.name
            }
          })
        })
        .finally(_ => { this.remoteLoading = false })
    },
    handleCloseList (status) {
      this.remoteListOpenStatus = status
    }
  }
}
</script>
<style lang="less" scoped>

</style>
